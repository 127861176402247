jQuery(document).ready(function($) {
  // activate mobile nav
  $('.btn-mobile-nav').click(function () {
    var txt = $('.wrap-nav').is(':visible') ? 'Menu' : 'Close';
    if( $('.wrap-nav').is(':visible') ) {
      $('span', this).text(txt);
      $('.wrap-nav').slideUp('fast');
      $(this).removeClass('stick');
      $('i', this).addClass('fa-bars').removeClass('fa-close');
    } else {
      $('span', this).text(txt);
      $('.wrap-nav').slideDown('fast');
      $(this).addClass('stick');
      $('i', this).removeClass('fa-bars').addClass('fa-close');
    }
  });

  // homepage background rotator
  $('.cycle-slideshow.bg-rotator').on('cycle-after',function(e,opts) {
    $('.cycle-slideshow.captions').cycle('goto',opts.nextSlide);
  });

  // insert twitter
  var config5 = {
    "id": '604371161867100160',
    "domId": '',
    "maxTweets": 3,
    "enableLinks": true,
    "showUser": true,
    "showTime": true,
    "dateFunction": dateFormatter,
    "showRetweet": false,
    "customCallback": handleTweets,
    "showInteraction": false
  };
  var m_names = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");
  function dateFormatter(date) {
    var curr_date = date.getDate();
    var curr_month = date.getMonth();
    var output =   m_names[curr_month] + " " + curr_date;
    return output;
  }
  function handleTweets(tweets){
    var x = tweets.length;
    var n = 0;
    var element = document.getElementById('latest-tweets');
    var html = '<ul data-equalizer>';
    while(n < x) {
      html += '<li data-equalizer-watch>' + tweets[n] + '</li>';
      n++;
    }
    html += '</ul>';
    element.innerHTML = html;
    $(document).foundation('equalizer', 'reflow');
  }
  twitterFetcher.fetch(config5);

  enquire.register('screen and (max-width: 40em)', function() {

  })
  .register('screen and (max-width: 64em)', function() {
    // open/close primary nav items
    $('.nav-primary > li > i').click(function () {
      var span = $(this);
      var child = $(this).next('ul');
      var parent = $(this).parent('li');

      if (child.is(':visible')) {
        child.hide();
        parent.removeClass('open');
        $(this).toggleClass('fa-angle-right').toggleClass('fa-angle-down');
      } else {
        span.parent('li').siblings().children('ul').hide();
        span.parent('li').siblings().find('.fa').addClass('fa-angle-right').removeClass('fa-angle-down');
        parent.addClass('open');
        $(this).toggleClass('fa-angle-right').toggleClass('fa-angle-down');
        child.show();
      }
    });
  });

    //Hide and Show the Newsletter Signup form
  $('.newsletter label').click(function () {
      $('.newsletter').toggleClass('maximized');
  });

  $('.newsletter-response').click(function () {
      $('.newsletter-response').hide();
  });
});
